import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect } from 'react';

import '../theme/global.scss';
import '../theme/normalize.css';

const HeaderComponent = dynamic(() =>
  import('../components/HeaderComponent/Header')
);
const Popup = dynamic(() => import('../components/Popup'));
const FooterComponent = dynamic(() => import('../components/Footer/Footer'));

function MyApp({ Component, pageProps }) {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.utag_cfg_ovrd = window.utag_cfg_ovrd || {};
      window.utag_cfg_ovrd.noview = true;
    }
  }, []);

  const router = useRouter();
  return (
    <>
      <Script
        id='tealium'
        strategy='beforeInteractive'
        src={`https://tags.tiqcdn.com/utag/vodafone/za-blog/${
          process.env.NODE_ENV === 'production' ? 'prod' : 'dev'
        }/utag.js`}
      />
      <HeaderComponent path={router.pathname} />
      <Popup />
      <Component {...pageProps} router={router} />
      <FooterComponent path={router.pathname} />
    </>
  );
}

export default MyApp;
